import React,{useState,useEffect} from 'react';
import PropTypes from "prop-types";

import DataGrid, {
 Column,
 Grouping,
 FilterRow,
 GroupPanel,
 Pager,
 Paging,
 SearchPanel,
 HeaderFilter,
} from 'devextreme-react/data-grid';
import { Navbar,Nav, NavLink } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';


export const NavHeader = () => {
  
    let location = useLocation();
      //const { match, location, history } = this.props;
            return <Navbar expand="lg">
    
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav variant='pills'>
            {/* <Nav.Item>
              <NavLink  eventKey="link-1" to={"/results"} as={Link}>Results</NavLink>
            </Nav.Item>
            <Nav.Item>
            <NavLink  eventKey="link-2" to={"/fixtures"} as={Link} >Fixtures</NavLink>
            </Nav.Item> */}
            <Nav.Item>
            <NavLink  eventKey="link-3" active={(location.pathname=='/oneresults' || location.pathname=='/')?true:false} to={"/oneresults"} as={Link} >Carrick Town Results</NavLink>
            </Nav.Item>     
            <Nav.Item>
            <NavLink  eventKey="link-4" active={(location.pathname=='/onefixtures')?true:false} to={"/onefixtures"} as={Link} >Carrick Town Fixtures</NavLink>
            </Nav.Item>        
          </Nav>
        </Navbar.Collapse>
     
    </Navbar>

    
    
  }
  
