import React from 'react';
import '../App.css';

import DataGrid, {
 Column,
 Grouping,
 FilterRow,
 GroupPanel,
 Pager,
 Paging,
 SearchPanel,
 HeaderFilter,
} from 'devextreme-react/data-grid';

export const ResultsComponent = () =>
{
  return <div className='App'><DataGrid rowAlternationEnabled={true} id="dataGrid" dataSource="https://tomasw23.sg-host.com/index.php/result/list?club=carrick"
  >
    <Paging pageSize={20}/>
    <SearchPanel visible={false} highlightCaseSensitive={true} />
    <FilterRow visible={true}
  applyFilter={true} />
<HeaderFilter visible={true} />
<Column dataField="ID" caption="ID" key="true" visible={false}></Column>
    <Column 
dataField="COMPETITION_NAME"
caption="Competition"
dataType="string"
allowFiltering={true}
cssClass="bullet"
/>
<Column dataField="HOME_TEAM" width={150}
caption="Home Team"
/>
   <Column dataField="HOME_SCORE"
caption="Home Score" width={120}
/>

<Column dataField="AWAY_SCORE"
caption="Away Score" width={120} alignment="left"
/>

<Column dataField="AWAY_TEAM"
caption="Away Team"
/>

 <Column dataField="FIXTURE_DATE"
caption="Fixture Date"
dataType="date" format="EEE dd MMMM yyyy"
/>

<Column dataField="AGE_GROUP"
caption="Age Group"
          />
<Column dataField="BOYS_GIRLS"
caption="Boys / Girls"
          />
<Column dataField="COMPETITION_TYPE"
caption="Competition Type"
          />

  </DataGrid></div>
}

