import 'devextreme/dist/css/dx.common.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.softblue.css';


import logo from './logo.svg';

import React from 'react';
import { Route,Routes } from 'react-router-dom';
import { FixturesComponent } from './Components/fixtures';
import { ResultsComponent } from './Components/results';
import { NavHeader } from './Components/navheader';
import { OneResultsComponent } from './Components/oneClubResults';
import { OneFixturesComponent } from './Components/oneClubFixtures';
import './AppCT.css';
class App extends React.Component {
  render() {
    return (

      <div style={{margin:"10px"}} >
      <NavHeader/>
<Routes>

  <Route path="/fixtures" element={ <FixturesComponent/>}>
   
  </Route>
  <Route path='/results' element={<ResultsComponent/>}>
    
  </Route>
  <Route path='/' element={<OneResultsComponent/>}>
    
    </Route>
    <Route path='/oneresults' element={<OneResultsComponent/>}>
    
    </Route>
  
    <Route path='/onefixtures' element={<OneFixturesComponent/>}>
    
    </Route>

</Routes>
      
</div>


      );
  }
  
}



export default App;

