import React from 'react';
import '../AppCT.css';

import DataGrid, {
  Column,
  Grouping,
  FilterRow,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import { RadioGroup } from 'devextreme-react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

export const genders = [
  { value: '', text: 'All' },
  { value: 'Boys', text: 'Boys' },
  { value: 'Girls', text: 'Girls' }
];


export class OneResultsComponent extends React.Component {

  
    constructor(props) {

    super(props);
    this.state =
    {
      genderFilter: '',
      ageFilter: '',
      items: [],
      ageGroups: [],
      selectedAge:'Select an Age Group'
    };

    this.filteredItems = [];


    this.onGenderFilterChanged = this.onGenderFilterChanged.bind(this);
    this.onAgeGroupChanged = this.onAgeGroupChanged.bind(this);
    this.getData();
  }

  render() {
    return <div className='App' >
      <div className='row' style={{"marginBottom":"20px"}}>
      <div className='col-md-3 option-container my-auto'>
        <DropdownButton className="age-group-button" onSelect={this.onDropdownSelected.bind(this)} id="ddButton" title={this.state.selectedAge}>
          {this.createAgeGroupItems()}
            </DropdownButton>
        </div>
        
        <div className='col-md-9 option-container my-auto'>
          <RadioGroup layout='horizontal' style={{float:"left"}} onValueChange={this.onGenderFilterChanged} id="radio-group-with-selection" items={genders} defaultValue=''
            valueExpr="value" displayExpr="text" />
        </div>
        
      </div>
      <DataGrid wordWrapEnabled={true} rowAlternationEnabled={true} id="dataGrid" dataSource={this.state.items}
      >
        <Paging pageSize={20} />
        <SearchPanel visible={false} highlightSearchText={false} highlightCaseSensitive={true} />
        <FilterRow visible={true}
          applyFilter={true} />
        <HeaderFilter visible={true} />
        <FilterRow visible={false} />
        <Column dataField="ID" caption="ID" key="true" dataType="number" visible={false}></Column>
        <Column
          dataField="COMPETITION_NAME"
          caption="Competition"
          dataType='string'
          allowFiltering={true}
          cssClass="bullet" minWidth={100}
        />
        <Column dataField="HOME_TEAM" minWidth={130}
          caption="Home Team" dataType='string'
        />
        <Column dataField="HOME_SCORE" dataType='string'
          caption="Home Score" minWidth={80}
        />

        <Column dataField="AWAY_SCORE" dataType='string'
          caption="Away Score" minWidth={80} alignment="left"
        />

        <Column dataField="AWAY_TEAM" minWidth={80}
          caption="Away Team" dataType='string'
        />

        <Column dataField="FIXTURE_DATE"
          caption="Fixture Date" width={170}
          dataType="date" format="EEE dd MMMM yyyy"
        />

        <Column dataField="AGE_GROUP" minWidth={75}
          caption="Age Group" filterValue={this.state.ageFilter} dataType='string'
        />
        <Column dataField="BOYS_GIRLS" minWidth={85} filterValue={this.state.genderFilter} dataType='string'
          caption="Boys / Girls"
        />
        <Column dataField="COMPETITION_TYPE"
          caption="Competition Type" dataType='string'
        />

      </DataGrid></div>
  }

  onGenderFilterChanged(e) {
    this.filteredItems = [];
    this.filteredItems.push({ "key": 'All', "desc": 'All' });

    var lookup = {};
    for (var item1, i = 0; item1 = this.state.items[i++];) {
      var name = item1.AGE_GROUP;

      if (!(name in lookup) && (item1.BOYS_GIRLS == e || e === '')) {
        lookup[name] = 1;

        this.filteredItems.push({ "key": item1.AGE_GROUP, "desc": item1.AGE_GROUP });
      }
    }



    // if(!selectedAgeInAvailableAges())
    // {
    //   this.setState()

    // }

    this.setState({
      genderFilter: e,
      ageGroups: this.filteredItems
    });

        if(e==='' || !this.selectedAgeInAvailableAges())
    {
      this.setState({selectedAge:'All', ageFilter:''});
    }
  }

  onAgeGroupChanged(e) {
    this.setState({
      ageFilter: e.value
    });
  }

  getData() {
    fetch("https://tomasw23.sg-host.com/index.php/result/list?club=carrick%20town")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
          this.getAgeGroups(this.state.items);
        },

        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }

      );
  }

  selectedAgeInAvailableAges()
  {
    var returnVal = false;
    
    for(var item,i=0;item=this.state.ageGroups[i++];)
    {
      
      if(item.desc == this.state.selectedAge)
      {
        
        returnVal=true;
      }
    }

    return returnVal;
  }

  getAgeGroups(items) {
    var lookup = {};
    var items = items;
    var result = [];
    result.push({ "key": 'All', "desc": 'All' });
    for (var item, i = 0; item = items[i++];) {
      var name = item.AGE_GROUP;

      if (!(name in lookup)) {
        lookup[name] = 1;
        result.push({ "key": name, "desc": name });

      }
    }
    //console.log(result);
    this.setState({ ageGroups: result });
  }

  createAgeGroupItems() {
    let items = [];
    var lookup = {};
    if(this.state.ageGroups.length > 1)
    {

      //console.log(this.state.ageGroups[0]);
      for (let i = 0; i < this.state.ageGroups.length; i++) {
      
        if(!(this.state.ageGroups[i].desc in lookup))
        {
          lookup[this.state.ageGroups[i]] = 1;
          //console.log(i);
          items.push(<Dropdown.Item active={(this.state.selectedAge===this.state.ageGroups[i].key)?true:false} key={this.state.ageGroups[i].key} eventKey={this.state.ageGroups[i].desc}>{this.state.ageGroups[i].desc}</Dropdown.Item>); 
        }
        
        }  
    }     
    return items;
  }

  onDropdownSelected(e) {
    this.setState({selectedAge:e, ageFilter:(e==='All')? '':e});

  }
}

