import React from 'react';
import '../App.css';
import DataGrid, {
      Column,
      Grouping,
      FilterRow,
      GroupPanel,
      Pager,
      Paging,
      SearchPanel,
      HeaderFilter,
     } from 'devextreme-react/data-grid';

export const FixturesComponent = ()=>{
      return <div className='App'>
 
      <DataGrid  rowAlternationEnabled={true} id="dataGrid" dataSource="https://tomasw23.sg-host.com/index.php/fixture/list"
      >
        <SearchPanel visible={false} highlightCaseSensitive={true} />
        <FilterRow visible={true}
      applyFilter={true} />
    <HeaderFilter visible={true} />
    <Column dataField="ID" caption="ID" key="true" visible={false}></Column>
        <Column 
    dataField="COMPETITION_NAME"
    caption="Competition"
    dataType="string"
    allowFiltering={true}
    cssClass="bullet"
  />
    <Column dataField="HOME_TEAM"
    caption="Home Team"
    />
    <Column dataField="AWAY_TEAM"
    caption="Away Team"
    />
     <Column dataField="FIXTURE_DATE"
    caption="Fixture Date"
    dataType="date" format="EEE dd MMMM yyyy"
    />
<Column dataField="FIXTURE_TIME"
    caption="Fixture Time"
    />
    <Column dataField="VENUE"
    caption="Venue"
              />
<Column dataField="AGE_GROUP"
    caption="Age Group"
              />
<Column dataField="BOYS_GIRLS"
    caption="Boys / Girls"
              />
<Column dataField="COMPETITION_TYPE"
    caption="Competition Type"
              />

<Column dataField="DATE_ADDED"
    caption="Date Updated"
    dataType="date" format="dd MMMM yyyy HH:mm:ss"
    />
          
      </DataGrid>          

      </div>
}


